import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles"; // ✅ Correct import
import LIFETIME_Logo from "../image/LIFETIME.png";
import DMS_Logo from "../image/DMS_Logo.png";
import ARROWLEFT from "../image/Arrow.png";

const LoginFileController = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const islargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Box sx={{
            width: "100vw", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 2,
            // background: "linear-gradient(135deg, #6e45e2, #88d3ce)"
        }}>
            <Box sx={{ width: "100%", textAlign: "center", mb: 6 }}>
                <Typography variant={isSmallScreen ? "h4" : "h2"} sx={{
                    color: "#000000",
                    fontSize: isSmallScreen ? "16px" : "26px",
                    // textShadow: "2px 2px 5px rgba(0,0,0,0.5)",
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif"
                }}>
                    Welcome to Distributer Management System
                </Typography>
            </Box>

            <Box sx={{
                border: "1px solid #E5E5E5",
                backgroundColor: "rgba(230, 230, 230, 0.2)",
                width: islargeScreen ? "100%" : "auto",
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: "center",
                justifyContent: "center",
                gap: isSmallScreen ? 4 : 6,
                p: 3
            }}>
                {[{
                    logo: LIFETIME_Logo,
                    alt: "Company logo",
                    text: "LIFESTYLE",
                    link: "https://dms1.wehear.in",
                    dms: true
                }, {
                    logo: DMS_Logo,
                    alt: "Company logo",
                    text: "HEARING SOLUTION",
                    link: "https://dms2.wehear.in"
                }].map((item, index) => (
                    <Paper
                        key={index}
                        sx={{
                            // padding: 4,
                            display: "flex",
                            flexDirection: "column",
                            cursor: "pointer",
                            width: isSmallScreen ? "90%" : 500,
                            height: isSmallScreen ? "15vh" : 350,
                            // borderTopRightRadius: 6,
                            boxShadow: "20px 20px 50px rgba(0, 0, 0, 0.3)",
                            transition: "transform 0.4s ease-in-out",
                            background: !item.dms
                                ? "linear-gradient(339deg, #80A7E5 -101.93%, #0E2548 71.64%)"
                                : "linear-gradient(50deg, #A91674 -133.33%, #F2653A 155.65%)",
                            '&:hover': {
                                transform: "translateY(-10px)",
                                boxShadow: "25px 25px 60px rgba(0, 0, 0, 0.5)"
                            },
                        }}
                        elevation={10}
                        onClick={() => window.open(item.link, "_blank")}
                    >

                        <Box sx={{
                            width: "100%",
                            height: "70%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <img src={item.logo} alt={item.alt} style={{ width: isSmallScreen ? "50px" : "220px" }} />
                        </Box>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexGrow: 1,
                            background: item.dms ? "#BB2A36" : "#0E2548"
                        }}>

                            <Typography sx={{
                                textAlign: "center",
                                fontSize: isSmallScreen ? "12px" : "20px",
                                color: "#fff",
                                fontWeight: "600",
                                mr: 3
                            }}>
                                {item.text}
                            </Typography>
                            <img src={ARROWLEFT} alt={"arrow Icon"} style={{ width: "2.5vw" }} />
                        </Box>
                    </Paper>
                ))}
            </Box>
        </Box >
    );
};

export default LoginFileController;
