import './App.css';
import LoginFileController from './page/LoginFileController';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();
function App() {
  return (
    <ThemeProvider theme={theme}>
      <LoginFileController />
    </ThemeProvider>
  );
}

export default App;
